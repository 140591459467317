import { useEffect, useState } from "react";
import axios from "axios";
import {h2Unlinked, castCard} from"./core/core";
import { apiRoot } from "./core/defines";
import SideNav from "./components/sideNav";

function NewFace(){
    
    const [newface, setNewface] = useState([])
    useEffect(()=>{
        
            const fetchNewface = async() =>{
                await axios({
                method: "get",
                url: `${apiRoot}/newface.php`
                })
                .then((res) => {
                    console.log("success");
                    console.log(res.data);
                    setNewface(res.data);
                })
                .catch(err =>{
                    console.log("err", err);
                });
            }
            fetchNewface();
    },[]);

    return(
        <div className="container bg-w-alpha">
            <section className="main-colmn">
                {h2Unlinked("NEW FACE")}
                <ul class="wrapper-sc">
                    {newface.map((cast) => 
                        <li className="card-cast" key={cast.c_id}>
                            {castCard(cast)}
                        </li>
                    )}
                </ul>

            </section>
            <SideNav />
        </div>
    )
}


export default NewFace