import { Link } from "react-router-dom"

function PcNav(){
    const NAV_TOP_PC = [{nameJp: "トップページ", nameEn: "TOP", path: "/home", blank: false, img: ""},
                        {nameJp: "スケジュール", nameEn: "SCHEDULE", path: "/schedule", blank: false, img: ""},
                        {nameJp: "新人情報",     nameEn: "NEW FACE", path: "/newface", blank: false, img: ""},
                        {nameJp: "在籍女性",     nameEn: "CASTS", path: "/allcasts", blank: false, img: ""},
                        {nameJp: "システム",     nameEn: "SYSTEM", path: "/system", blank: false, img: ""},
                        {nameJp: "ランキング",   nameEn: "RANKING", path: "/ranking", blank: false, img: ""},
                        {nameJp: "アクセス",     nameEn: "ACCESS", path: "/access", blank: false, img: ""},
//                      {nameJp: "内装紹介",     nameEn: "INTERIOR", path: "/interior", blank: true, img: ""},
                        {nameJp: "写メ日記",     nameEn: "DIARY", path: "https://www.cityheaven.net/ibaraki/A0801/A080101/mt-marin-blue/diarylist/?of=y", blank: true,},
//                      {nameJp: "ムービー",     nameEn: "MOVIE", path: "/", blank: true, img: ""},
                        {nameJp: "ネット予約",   nameEn: "RESERVE", path: "https://www.cityheaven.net/ibaraki/A0801/A080101/mt-marin-blue/A6ShopReservation/?of=y", blank: true},
                        {nameJp: "口コミ",       nameEn: "REVIEW", path: "https://www.cityheaven.net/ibaraki/A0801/A080101/mt-marin-blue/reviews/?of=y", blank: true},
                        {nameJp: "男子求人",     nameEn: "RECRUIT", path: "https://mens-qzin.jp/ibaraki/area_08001/detail/marinebluemito/?v=official", blank: true},
                        {nameJp: "女子求人",     nameEn: "RECRUIT", path: "https://www.girlsheaven-job.net/ibaraki/ma-165/sa-495/mt-marin-blue/?of=y", blank: true},
    ];

    return(
        <div id="pc-header" className="pc">
            <nav className="wrapper-pcnav">
                <ul className="pcnav txt-center">
                    {NAV_TOP_PC.map((el)=>{
                        if(el.blank===false){
                            return(<li key={el.nameEn}><Link to={el.path}>{el.nameJp}<span>{el.nameEn}</span></Link></li>); }
                        else{ return(<li key={el.nameEn}><a href={el.path}
                                            target="_blank" rel="noreferrer noopner">{el.nameJp}<span>{el.nameEn}</span></a></li>); }
                    })}
                </ul>
            </nav>
        </div>
    );

}

export default PcNav;